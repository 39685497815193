angular.module('cerberus')
    // == app version ==
    .constant('coreAppVersion', {            // main app version (should match everywere)
        version: '1.7.443',
        build: 'Build: 1.0',
        date: '2024-11-19',
    })
    // == api path ==
    .constant('apiPath', '/server/rest/v1/') // with trailing slash
    // == system generic constants ==
    .constant('userDefinedIdRegex', /^[A-Za-z][A-Za-z0-9]{2,19}$/)
    .constant('statusList',{
        1: { name: 'Active', color: 'success' },
        2: { name: 'Deleted', color: 'danger' },
        3: { name: 'Disabled', color: 'warning' },
        4: { name: 'Pending', color: 'info' },
        5: { name: 'Failed', color: 'danger' },
        6: { name: 'Complete', color: 'primary' }
    })
;
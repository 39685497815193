angular.module('cerberus.admin')
    .controller('FormsDesignerCtrl',
    function FormsDesignerCtrl(_, moment, $scope, $location, $log, $timeout, $uibModal, formOriginId, widgetId,
                               CalculationService, ConfirmModalService, FormsService, FormsClassService,
                               FormsDesignerConvenienceService, FormsDesignerService, WidgetsService,
                               FormsDesignerListService, DesignerUtilityService, nimCalcService) {
        // Determine Originator Id
        $scope.originPubId = formOriginId;
        $scope.originId = _.last(formOriginId.split('-'));

        $scope.widgetId = widgetId;

        // Set models
        $scope.temp = {};
        $scope.orig = {};
        $scope.revisions = [];

        // Set data containers
        $scope.fieldOptions = {};
        $scope.dataSet = {};
        $scope.filters = {};
        $scope.fileAttachments = {};

        // Printer Variables
        $scope.pageCounter = '####';
        $scope.pageCurrent = '#';
        $scope.pageTotal = '#';

        // Print/Export FileName Systax Examples
        $scope.FileNameFieldFormat = [
            {value: '{0}', display: '[Field]'},
            {value: 'PO_{0}', display: 'PO_[Field]'},
            {value: 'Invoice {0}', display: 'Invoice [Field]'},
            {value: '{0} SalesOrder', display: '[Field] SalesOrder'},
            {value: '{0} - {1:yyyy-MM-dd}', display: '[Field] - Y-M-D'},
            {value: '{1:yyyy-MM-dd} - {0}', display: 'Y-M-D - [Field]'},
            {value: '{0}_{1:yyyy-MM-dd}', display: '[Field]_Y-M-D'},
            {value: '{1:yyyy-MM-dd}_{0}', display: 'Y-M-D_[Field]'},
            {value: '{0} ({1:yyyy-MM-dd})', display: '[Field] (Y-M-D)'},
            {value: '({1:yyyy-MM-dd}) {0}', display: '(Y-M-D) [Field]'},
            {value: '{0} - {1:yyyy-MM-dd HH_mm_ss}', display: '[Field] - Y-M-D hh_mm_ss'},
            {value: '{1:yyyy-MM-dd HH_mm_ss} - {0}', display: 'Y-M-D hh_mm_ss - [Field]'}
        ];

        // UI Variables & Functions
        $scope.canSave = true;
        $scope.designer = {
            activeSection: false,
            width: 1024
        };
        $scope.revisionsCollapse = true;
        $scope.settingsCollapse = true;
        $scope.hiddenFieldsCollapse = true;
        $scope.warningCollapse = true;
        $scope.warnings = [];
        $scope.formTypes = [
            {value: 'normal', display: 'Normal'},
            {value: 'check', display: 'Check'},
            {value: 'label', display: 'Label'}
        ];
        $scope.gridsterOpts = {
            pushing: false,
            swapping: false,
            resizable: {
                enabled: true
            },
            draggable: {
                enabled: true
            }
        };

        var formObjects = FormsDesignerListService.formObjects();

        $scope.sectionFilter = FormsService.sectionFilter;
        $scope.addSectionToForm = FormsDesignerService.addSectionToForm;
        $scope.removeSectionFromForm = removeSectionFromForm;
        $scope.reorderItem = reorderItem;
        $scope.updateSection = FormsDesignerService.updateSection;
        $scope.sectionConditionString = FormsDesignerService.sectionConditionString;
        $scope.numberFilter = numberFilter;
        $scope.preventInvalidCharacters = preventInvalidCharacters;
        //$scope.preventInvalidFileName = preventInvalidFileName;
        $scope.removeInvalidCharacters = removeInvalidCharacters;
        $scope.showFormObject = showFormObject;
        $scope.disableFormObject = disableFormObject;
        $scope.requireFormObject = requireFormObject;
        /* Toolbar Functions */
        $scope.createTemplate = function(){
            $location.url('/settings/widgets/' + widgetId + '/forms/' + formOriginId + '/template');
        };
        $scope.hasChanges = function () {
            return !angular.equals($scope.orig, $scope.temp);
        };
        //Check for changed data
        DesignerUtilityService.cancelRouteNav($scope, $scope.hasChanges);

        $scope.preview = function () {
            // TODO: Launch a modal with a specific class for size
        };

        $scope.delForm = function(data){
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/widgets/forms/delete-form-modal.tpl.html',
                controller: 'DeleteWidgetComponentsModalCtrl',
                resolve: {
                    formOriginId: _.constant(formOriginId),
                    data: _.constant(data)
                }
            });
            modalInstance.result.then(function (data) {
                FormsDesignerService.remove(data.originatorId, data.widgetId).then(function(){
                    $location.url('/settings/widgets/' + data.widgetId + '/forms');
                });
            });
        };

        $scope.save = function (originPubId, data, publish) {
            $scope.canSave = false;
            FormsDesignerService.createRevision(originPubId, data).then(function(value){
                if (publish) {
                    FormsDesignerService.publish(value).then(function(){
                        $scope.temp.published = true;
                        $scope.orig.published = true;
                        _init();
                    });
                }
                else{
                    $scope.temp.published = false;
                    _init();
                }
                $scope.temp.formId = value;
                $scope.orig = angular.copy($scope.temp);
            }).finally(function () {
                $scope.canSave = true;
            });
        };
        //$scope.saveAndClose = function (originPubId, data) {
        //    $scope.canSave = false;
        //    FormsDesignerService.createRevision(originPubId, data).then(
        //        function(){
        //            $scope.orig = angular.copy($scope.temp);
        //            $location.url('/settings/widgets/' + data.widgetId + '/forms');
        //        }, function () {
        //            $scope.canSave = true;
        //        }
        //    );
        //};
        $scope.publish = function(originPubId, data){
            if ($scope.hasChanges() === true) {
                $scope.save(originPubId, data, true);
            }
            else {
                FormsDesignerService.publish(data.formId).then(function () {
                    $scope.temp.published = true;
                    $scope.orig.published = true;
                    getRevisions();
                });
            }
        };
        $scope.close = function () {
            $location.url('/settings/widgets/' + widgetId + '/forms');
        };

        /* Revisions Panel */
        $scope.getForm = function (id) {
            FormsService.getForm(id, true).then(function(form){
                $scope.temp = angular.copy(form);
                $scope.orig = angular.copy(form);
                $scope.revisionsCollapse = true;
            });
        };

        /* Form Manipulation */
        $scope.createFormObject = function(type){
            var newFormObject = {
                model: FormsClassService[type](),
                //Define width based on type
                sizeY: formObjects[type].defaultSizeY,
                sizeX: formObjects[type].defaultSizeX
            };
            $scope.openFormObjectConfigModal(newFormObject,-1);
        };

        $scope.createConvenienceObject = function(type, convenienceType){
            var newFormObject = {
                model: FormsDesignerConvenienceService[convenienceType](),
                //Define width based on type
                sizeY: formObjects[type].defaultSizeY,
                sizeX: formObjects[type].defaultSizeX
            };
            $scope.openFormObjectConfigModal(newFormObject,-1);
        };

        $scope.createHiddenField = function(type){
            var newHiddenObject = {
                model: FormsDesignerConvenienceService[type](),
                sizeY: 0,
                sizeX: 0
            };
            $scope.openFormObjectConfigModal(newHiddenObject, -1);
        };

        // Function for creating geocoder with auto-populated fields
        $scope.createGeocodeArray = function(){
            // Create Geocode Field
            var geocoder = {
                model: FormsClassService.location(),
                sizeY: formObjects.location.defaultSizeY,
                sizeX: formObjects.location.defaultSizeX
            };
            geocoder.model.display.section = $scope.designer.activeSection;

            // Configures Geocode Field
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/widgets/forms/designer/common/config-modal.tpl.html',
                controller: 'FormsDesigner_stringCtrl',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    autoTabIndex: _.constant($scope.temp.form.settings.autoTabIndex.enabled),
                    currentFormObjects: getCurrentFormObjects,
                    editFlag: _.constant(false),
                    formOriginId: _.constant($scope.originId),
                    formObj: _.constant(geocoder),
                    idArray: _.constant(_.map($scope.temp.form.objects, 'model.config.modelId')),
                    sections: _.constant([{val: false, display: '(Default)'},{val: '__nimHiddenSection', display: '(Hidden)'}].concat($scope.temp.form.sections.map(function(s){ return {val: s.name, display: s.name}; }))),
                    widgetId: _.constant(widgetId)
                }
            });

            modalInstance.result.then(function (formObj) {
                //Set Default Values
                var defaultVal = formObj.model.config.defaultValue;
                if(defaultVal || defaultVal === false || defaultVal === 0){
                    defaultVal = FormsService.parseDefaultValue(formObj.model.config.type,formObj.model.config.defaultValue);
                    if(!formObj.model.param.filter) {
                        $scope.dataSet[formObj.model.config.modelId] = angular.copy(defaultVal);
                    }
                    $scope.filters[formObj.model.config.modelId] = angular.copy(defaultVal);
                }

                $scope.temp.form.objects.push(formObj);

                if (formObj.model.calculation.formula || !_.isEmpty(formObj.model.calculation.tokens)) {
                    formObj.model.calculation.formula = _.trim(formObj.model.calculation.formula);
                    formObj.model.calculation.tokens = nimCalcService.parse(formObj.model.calculation.formula).items;
                    var tokenCpy = angular.copy(formObj.model.calculation.tokens);
                    parseFormObjectTokens(tokenCpy ,formObj.model.config.modelId);
                }

                // Creates other fields
                var geoId = formObj.model.config.modelId;
                var formId = $scope.originId;

                var addressFormObject = {
                    model: FormsClassService.text(),
                    sizeY: formObjects.readonly.defaultSizeY,
                    sizeX: formObjects.readonly.defaultSizeX
                };
                addressFormObject.model.display.section = geocoder.model.display.section;
                var fields = formObj.model.param.fields;
                var geoFields = [
                    {name: 'Street Address',value: 'street_address'},
                    {name: 'City',value: 'locality_long_name'},
                    {name: 'ZIP Code',value: 'postal_code_short_name'},
                    {name: 'State',value: 'administrative_area_level_1_short_name'},
                    {name: 'County',value: 'administrative_area_level_2_long_name'},
                    {name: 'Country',value: 'country_long_name'}
                ];
                var setFields = _.map(fields, 'value');
                for(var gf = 0; gf < geoFields.length; gf++){
                    var gField = geoFields[gf];
                    if(setFields.indexOf(gField.value) < 0){
                        var newId = geoId + gField.name.replace(/ /g, '');
                        fields[newId] = {
                            form: formId,
                            usage: "'" + newId + "'",
                            value: gField.value
                        };
                        var newField = angular.copy(addressFormObject);
                        newField.model.label.text = gField.name;
                        newField.model.config.modelId = newId;
                        $scope.temp.form.objects.push(newField);
                    }
                }
            });
        };

        $scope.getInnerFieldOption = function(fieldObj){
            var f = {option:fieldObj.option};
            FormsDesignerService.getFieldOption(f).then(function(value){
                $scope.fieldOptions[fieldObj.field] = value;
            });
        };

        $scope.copyFormObject = function (obj) {
            var formObj = angular.copy(obj);
            formObj.row += formObj.sizeY;
            //Change model id if exists
            if(formObj.model.config.modelId){
                var cpyNum = formObj.model.config.modelId.substr(formObj.model.config.modelId.length - 1);
                var newModelId;
                if(isNaN(cpyNum)){
                    newModelId = formObj.model.config.modelId + '1';
                }
                else{
                    var b = parseInt(cpyNum) + 1;
                    newModelId = formObj.model.config.modelId.substring(0,formObj.model.config.modelId.length - 1) + b;
                }
                formObj.model.config.modelId = newModelId;
            }
            //Change label text
            formObj.model.label.text = 'Copy Of ' + formObj.model.label.text;
            $scope.temp.form.objects.push(formObj);
            //Copy custom values
            if(formObj.model.option.values.length > 0){
                $scope.fieldOptions[formObj.model.config.modelId] = formObj.model.option.values;
            }
            //Copy default values
            var defaultValue = formObj.model.config.defaultValue;
            if(defaultValue || defaultValue === false || defaultValue === 0){
                if(!formObj.model.param.filter){
                    $scope.dataSet[formObj.model.config.modelId] = angular.copy(defaultValue);
                }
                //else{
                    $scope.filters[formObj.model.config.modelId] = angular.copy(defaultValue);
                //}
            }
        };
        $scope.deleteBox = function (box) {
            var idx = $scope.temp.form.objects.indexOf(box);
            FormsDesignerService.breakingChangesCheck($scope.temp.formId,box.model.config.modelId).then(function(msg){
                if(msg.length > 0){
                    ConfirmModalService.showModal(null, {
                        headerText: 'Confirm Deletion',
                        bodyText: msg + '<br> <strong>Are you sure you want to delete?</strong>',
                        actionButtonText: 'Delete',
                        closeButtonText: 'Cancel',
                        confirm: function(){
                            $scope.temp.form.objects.splice(idx, 1);
                            $scope.warnings.push(msg);
                        },
                        check: function(){
                            return true;
                        }
                    });
                }
                else{
                    $scope.temp.form.objects.splice(idx, 1);
                }
            });
        };

        // Form Object Configuration Modal
        $scope.openFormObjectConfigModal = function (formObject) {
            var index = $scope.temp.form.objects.indexOf(formObject);

            if(index < 0 && !formObject.model.display.section){
                formObject.model.display.section = $scope.designer.activeSection;
            }

            var decodeHtml = function (html) {
                var txt = document.createElement("textarea");
                txt.innerHTML = html;
                return txt.value;
            };
            if(formObject.model.config.type === 'statictext'){
                formObject.model.param.text = decodeHtml(formObject.model.param.text);
            }

            var dataType = formObject.model.config.dataType;
            if(formObject.model.config.type === 'map'){
                dataType = 'map';
            }
            else if(formObject.model.config.type === 'location'){
                dataType = 'string';
            }

            var modalInstance = $uibModal.open({
                templateUrl: 'admin/widgets/forms/designer/common/config-modal.tpl.html',
                controller: 'FormsDesigner_' + dataType + 'Ctrl',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    autoTabIndex: _.constant($scope.temp.form.settings.autoTabIndex.enabled),
                    currentFormObjects: getCurrentFormObjects,
                    editFlag: _.constant(index > -1),
                    formObj: _.constant(formObject),
                    formOriginId: _.constant($scope.originId),
                    idArray: _.constant(_.map($scope.temp.form.objects, 'model.config.modelId')),
                    sections: _.constant([{val: false, display: '(Default)'},{val: '__nimHiddenSection', display: '(Hidden)'}].concat($scope.temp.form.sections.map(function(s){ return {val: s.name, display: s.name}; }))),
                    widgetId: _.constant(widgetId)
                }
            });

            modalInstance.result.then(function (formObj) {
                //Set Default Values
                var defaultValue = formObj.model.config.defaultValue;
                if(defaultValue || defaultValue === false || defaultValue === 0){
                    var interval = null;

                    // For parsing default date value to nearest interval
                    if(formObj.model.config.type === 'datetime' && formObj.model.display.format.match(/[HhfmstgG]/g)){
                        interval = formObj.model.tag.attrs['k-interval'];
                    }

                    var defaultVal = FormsService.parseDefaultValue(formObj.model.config.type, defaultValue, interval);
                    if(!formObj.model.param.filter) {
                        $scope.dataSet[formObj.model.config.modelId] = angular.copy(defaultVal);
                    }
                    //else{
                        $scope.filters[formObj.model.config.modelId] = angular.copy(defaultVal);
                    //}
                }
                //Populate options object
                if(formObj.model.option.type === 'custom'){
                    $scope.fieldOptions[formObj.model.config.modelId] = formObj.model.option.values;
                }
                //else if (formObj.model.option.type === 'groups' ){
                //    var d = {option:formObj.model.option};
                //    FormsDesignerService.getFieldOption(d).then(function(value){
                //        $scope.fieldOptions[$scope.originId][formObj.model.config.modelId] = value;
                //    });
                //}

                //for tables
                if(formObj.model.config.type === 'table'){
                    for(var i = 0; i < formObj.model.tag.inner.length; i++){
                        if(formObj.model.tag.inner[i].option){
                            if(formObj.model.tag.inner[i].option.type === 'custom'){
                                $scope.fieldOptions[formObj.model.tag.inner[i].field] = formObj.model.tag.inner[i].option.values;
                            }
                            else{
                                $scope.getInnerFieldOption(formObj.model.tag.inner[i]);
                            }
                        }
                    }
                }
                //Editing a form object.
                if(index > -1){
                    $scope.temp.form.objects.splice(index, 1, formObj);
                }
                //Creating a new form object.
                else{
                    $scope.temp.form.objects.push(formObj);
                }

                if(formObj.model.calculation.formula || !_.isEmpty(formObj.model.calculation.tokens)){
                    formObj.model.calculation.formula = _.trim(formObj.model.calculation.formula);
                    formObj.model.calculation.tokens = nimCalcService.parse(formObj.model.calculation.formula).items;
                    var tokenCpy = angular.copy(formObj.model.calculation.tokens);
                    parseFormObjectTokens(tokenCpy ,formObj.model.config.modelId);
                }
            });
        };

        $scope.openSectionModal = function(form, section){
            _.defaults(form, {sections: []});
            var index = form.sections.indexOf(section),
                sectionNames = _.map(form.sections, 'name');


            // Includes checkbox/radios/drop-downs of type "custom" or "views" and excludes fields in this section
            var fields = _.filter(form.objects, function(formObj){
                var type = _.get(formObj, 'model.config.type'),
                    dataType = _.get(formObj, 'model.config.dataType'),
                    lookupType = _.get(formObj, 'model.option.type');

                return lookupType === 'custom' ||
                    lookupType === 'views' ||
                    type === 'checkbox' ||
                    dataType === 'string' ||
                    dataType === 'number';
            });

            if(index >= 0){
                _.pull(sectionNames, section.name);
                /* LODASH Version 4 ONLY */
                //fields = _.reject(fields, ['model.display.section', section.name]);
                /* LODASH Version 3 & 4 Compatibility Mode */
                fields = _.reject(fields, function(r){return r.model.display.section === section.name;});
            }

            sectionNames.push('__nimHiddenSection'); // Add name of hidden section

            var modalInstance = $uibModal.open({
                templateUrl: 'admin/widgets/forms/designer/section/section-config-modal.tpl.html',
                controller: 'FormsDesigner_SectionCtrl',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    section: _.constant(section),
                    sectionArray: _.constant(sectionNames),
                    formOriginId: _.constant($scope.originId),
                    fields: _.constant(fields)
                }
            });

            modalInstance.result.then(function(data){
                if(index >= 0){
                    $scope.updateSection(form, data, index);
                }
                else {
                    $scope.addSectionToForm(form, data);
                }
                $scope.designer.activeSection = data.name;
            });
        };

        /* Formulas */
        //Functions for parsing form objects with formulas
        function parseFormObjectTokens(tokenArray, resultModelId){
            var hasVariables = false,
                resultObj = {
                id: $scope.originId,
                model: resultModelId
            };
            for(var k = 0; k < tokenArray.length; k++){
                if(tokenArray[k].type === 'operand' && tokenArray[k].subtype === 'range'){
                    var tokValArr = tokenArray[k].value.split('_'),
                        operandModelId = _.last(tokValArr);

                    if(!$scope.dataSet[operandModelId]){
                        $scope.dataSet[operandModelId] = '';
                    }

                    tokenArray[k].value = $scope.dataSet[operandModelId];

                    if(operandModelId.substring(0,1) !== '@'){
                        createWatcher($scope.originId, operandModelId, tokenArray, k, resultObj);
                        hasVariables = true;
                    }
                }
            }

            if(!hasVariables){
                var result = CalculationService.calculate(tokenArray);
                if(result.type !== 'error') {
                    $scope.dataSet[resultModelId] = result.value;
                }
            }
        }

        function createWatcher(formOriginId, modelId, tokenArr, tokIndex, resultVar){
            tokenArr[tokIndex].value = '';
            modelId = _.trim(modelId);
            if (modelId) {
                $scope.$watch('dataSet["' + modelId + '"]', function (newValue) {
                    tokenArr[tokIndex].value = newValue;

                    // Checks if empty lookup value
                    if (angular.equals(newValue, {}) || (_.get(newValue, 'id') === '-1')) {
                        tokenArr[tokIndex].value = '';
                    }

                    if (modelId !== resultVar.model) {
                        var result = CalculationService.calculate(tokenArr),
                            resultValue = result.value;

                        if (result.type === 'error') {
                            $log.warn(resultVar.model, '\t', result.value);
                            resultValue = null;
                        }

                        $scope.dataSet[resultVar.model] = resultValue;

                        if (_.has(resultValue, 'id') && resultValue.id === '-1') {
                            $scope.filters[resultVar.model] = null;
                        }
                        else {
                            $scope.filters[resultVar.model] = resultValue;
                        }
                    }
                });
            }
        }

        /* Init */
        _init();

        $scope.$on('$locationChangeStart', function (e) {
            if (!e.defaultPrevented) {
                $scope.$broadcast('nim-remove-form-objects');
            }
        });

        function _init(){
            FormsService.getForm(formOriginId).then(function(formModel){
                $scope.temp = angular.copy(formModel);
                $scope.orig = angular.copy(formModel);

                _.defaultsDeep($scope.temp.form.settings, {
                    print: {
                        type: 'normal',
                        pageSize: [8.5, 11],
                        pageTotal: '',
                        margin: {
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0
                        }
                    }
                });

                initFormVars();
            });

            getRevisions();
        }

        function initFormVars(){
            $scope.fieldOptions = $scope.temp.fieldOptions || {};
            //$scope.temp.form.settings = $scope.temp.form.settings;
            $scope.dataSet = angular.copy($scope.temp.defaultDataSet);
            for(var i = 0; i < $scope.temp.form.objects.length; i++){
                var formObj = $scope.temp.form.objects[i];
                var modelId = formObj.model.config.modelId;
                if(formObj.model.calculation.formula){
                    var tokensCopy = angular.copy(formObj.model.calculation.tokens);
                    parseFormObjectTokens(tokensCopy, modelId);
                }
                var defaultValue = formObj.model.config.defaultValue;
                if(defaultValue || defaultValue === false || defaultValue === 0){
                    var interval = null;

                    // For parsing default date value to nearest interval
                    if(formObj.model.config.type === 'datetime' && formObj.model.display.format.match(/[HhfmstgG]/g)){
                        interval = formObj.model.tag.attrs['k-interval'];
                    }

                    var defaultVal = FormsService.parseDefaultValue(formObj.model.config.type, defaultValue, interval);
                    if(!formObj.model.param.filter){
                        $scope.dataSet[modelId] = angular.copy(defaultVal);
                    }
                    //else{
                    $scope.filters[modelId] = angular.copy(defaultVal);
                    //}
                }
            }
        }

        function getRevisions(){
            FormsDesignerService.getRevisions(formOriginId).then(function(revisions){
                _.forEach(revisions, function(rev){
                    rev.createdDate = moment.utc(rev.createdDate, 'MMMM, DD YYYY HH:mm:ss').toDate();
                });
                $scope.revisions = revisions;
            });
        }

        function removeSectionFromForm(form, index){
            if($scope.designer.activeSection === form.sections[index].name){
                $scope.designer.activeSection = false;
            }
            FormsDesignerService.removeSectionFromForm(form, index);
        }

        function reorderItem(array, oldIndex, newIndex){
            var item = array.splice(oldIndex, 1)[0];
            array.splice(newIndex, 0, item);
        }

        function getCurrentFormObjects(){
            var objects = [],
                operands = [],
                formId = _.last($scope.temp.formId.split('-'));

            _.forEach($scope.temp.form.objects, function(formObj){
                var usage = "'" + formObj.model.config.modelId + "'",
                    currentFormObject = {
                        modelId: formObj.model.config.modelId,
                        type: formObj.model.config.type,
                        dataType: formObj.model.config.dataType,
                        label: formObj.model.label.text,
                        formId: $scope.temp.originatorId,
                        realFormId: formId,
                        usage: usage,
                        isLookUp: formObj.model.option.type === 'views',
                        isCustomLookup: formObj.model.option.type === 'custom'
                    };

                if(currentFormObject.isLookUp){
                    currentFormObject.widgetId = formObj.model.option.widgetId;
                }
                else if (currentFormObject.dataType === "dataseries"){
                    currentFormObject.widgetId = formObj.model.param.widgetId;
                }

                operands.push(usage);
                objects.push(currentFormObject);
            });

            return {
                display: {
                    formId: formId,
                    originId: $scope.temp.originatorId,
                    formName: $scope.temp.name,
                    hasUpload: $scope.temp.form.settings.nimupload.enabled,
                    objects: objects
                },
                operands: operands
            };
        }

        function numberFilter(formObj){
            return formObj.model.config.type === 'number';
        }

        function preventInvalidCharacters(event){
            var period = event.which === 190,
                underscore = event.which === 189 && event.shiftKey;

            if(period || underscore){
                event.preventDefault();
            }
        }

//        function preventInvalidFileName(event){
//            try {
//                if (/^[a-zA-Z0-9\_\- ]*$/.test(event.key)) {
//                    return true;
//                } else {
//                    event.preventDefault();
//                    return false;
//                }
//            } catch (event) {
//            }
//        }

        function removeInvalidCharacters(){
            $timeout(function(){
                if($scope.temp.name){
                    $scope.temp.name = $scope.temp.name.replace(/[._]/g, '');
                }
            });
        }

        function showFormObject(formObject){
            if(!_.isEmpty(formObject.param.condition) && (formObject.param.conditionalAction === 'show' || formObject.param.conditionalAction === 'hide'|| !formObject.param.conditionalAction)){
                var result = FormsService.sectionCondition(formObject.param.condition, $scope.dataSet, formObject.param.conditionalLogic, 'create');

                if(formObject.param.conditionalAction === 'hide'){
                    return !result;
                }

                return result;
            }

            return true;
        }

        function disableFormObject(formObject){
            if(!_.isEmpty(formObject.param.condition) && (formObject.param.conditionalAction === 'active' || formObject.param.conditionalAction === 'readonly')){
                var result = FormsService.sectionCondition(formObject.param.condition, $scope.dataSet, formObject.param.conditionalLogic, 'create');

                if(formObject.param.conditionalAction === 'active'){
                    return !result;
                }
                else if(formObject.param.conditionalAction === 'readonly'){
                    return result;
                }
            }

            return false;
        }

        function requireFormObject(formObject) {
            if(!_.isEmpty(formObject.param.condition) && (formObject.param.conditionalAction === 'required' || formObject.param.conditionalAction === 'optional')){
                var result = FormsService.sectionCondition(formObject.param.condition, $scope.dataSet, formObject.param.conditionalLogic, 'create');

                if(formObject.param.conditionalAction === 'optional'){
                    return !result;
                }

                return result;
            }

            return false;
        }
    })
;
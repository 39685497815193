angular.module('cerberus.admin')
/**
 * @ngdoc service
 * @name WorkflowsClassService
 * @alias cerberus/workflows-designer:WorkflowsClassService
 * @description
 *  Constructs Workflow and state classes
 */
.factory('WorkflowsClassService', function WorkflowsClassService(_){

        // Describes default parameters for process subtypes
        var defaultParams = {
            email: {
                bcc: {text: '', modelId: ''},
                cc: {text: '', modelId: ''},
                body: {text: '', modelId: ''},
                subject: {text: '', modelId: ''},
                to: {text: '', modelId: ''},
                template: {modelId: 'wflowDefaultTemplate'},
                account: {modelId: 'accountDefault'},
                showHeader: {modelId: true},
                showButton: {modelId: true},
                showSettings: {modelId: true}
            },
            createInstance: {
                targetWidgetId: '',
                map: [],
                fk: {}
            },
            updateInstance: {
                targetWidgetId: '',
                map: [],
                lookupModelId: '',
                type: '',
                fk: ''
            },
            notification: {
                pkColumn: '',
                viewId: '',
                staticMsgColumn1: '',
                viewMsgColumn1: '',
                staticMsgColumn2: '',
                viewMsgColumn2: '',
                staticMsgColumn3: '',
                groupIds: []
            }
        };

        // Container with global settings from Dropdown Configurations. 
        // Used in miscellaneous drop down select fields within workflow.
        var containerGlobalConfigSelections = {
            procSubTypes : [
                { type: 'email', display: 'Email' },
                { type: 'createInstance', display: 'Instance Creation' },
                { type: 'updateInstance', display: 'Instance Update' },
                { type: 'notification', display: 'Notification' }
            ],
            emailTemplates : [
                {label: 'wflowDefaultTemplate', display: 'Default - Designed for Internal usage'},
                {label: 'wflowOrderCreated', display: 'Sales Order - S.O. Created'},
                {label: 'wflowOrderStaged', display: 'Sales Order - S.O. Ready to ship'},
                {label: 'wflowOrderShipped', display: 'Sales Order - S.O. Shipped'}
            ],
            emailAccounts : [
                {label: 'default', display: 'Default - Default server provided account'},
                {label: 'csr', display: 'CSR - Customer Service Dept.'},
                {label: 'salesrep', display: 'Sales Rep - Sales & Quote follow up'},
                {label: 'shipping', display: 'Shipping - Shipping Dept.'},
                {label: 'accounting', display: 'Accounting - Accounting Dept.'},
                {label: 'executives', display: 'Executives - Officers and CEO'}
            ]
        };

        /**
         * Constructs new Workflow
         * @class Workflow
         * @classdesc for holding workflow data
         * @memberOf WorkflowsClassService
         * @param {object} tasks
         * @param {object} objects
         */
        function Workflow(tasks, objects){
            this.tasks = tasks || {};
            this.objects = objects || {};
        }

        /**
         * Constructs new State
         * @class State
         * @classdesc describes workflow state
         * @param {object} model
         * @param {Display} display
         */
        function State(model, display){
            this.model = model;
            this.display = display;
        }

        /**
         * Constructs new Decision
         * @class Decision
         * @classdesc for describing workflow decision
         * @memberOf WorkflowsClassService
         * @param {string} name
         * @param {object} to
         */
        function Decision(name, to){
            this.name = name || '';
            this.type = 'decision';
            this.to = to || {};
        }

        /**
         * Constructs new Document
         * @class Document
         * @classdesc for describing workflow document
         * @memberOf WorkflowsClassService
         * @param {string} name
         * @param {array} to
         * @param {array} forms
         * @param {object} permissions
         * @param {boolean} isFinal
         * @param {boolean} editable
         */
        function Document(name, to, forms, permissions, isFinal, editable){
            this.name = name || '';
            this.type = 'document';
            this.to = to || [];
            this.forms = forms || [];
            this.permissions = permissions || {};
            this.isFinal = isFinal || false;
            this.editable = editable || !isFinal;
        }

        /**
         * Constructs new Process
         * @class Process
         * @classdesc describes a workflow process
         * @memberOf WorkflowsClassService
         * @param {string} name
         * @param {array} to
         * @param {string} subType
         * @param {object} params
         */
        function Process(name, to, subType, params){
            this.name = name || '';
            this.type = 'process';
            this.to = to || [];
            this.subType = subType || '';
            this.params = params || getParams(subType);
        }

        /**
         * Constructs Display property for State object
         * @class Display
         * @classdesc describes a state's display properties
         * @param {number} order
         */
        function Display(order){
            this.order = order;
        }

        /**
         * Retrieves default params for the given process type
         * @memberOf WorkflowsClassService
         * @param type
         * @returns {object}
         */
        function getParams(type){
            return angular.copy(_.get(defaultParams, type, {}));
        }

        /**
         * Retrieves default settings from generic container
         * @memberOf WorkflowsClassService
         * @param objKey
         * @returns {object}
         */
        function getDefaultSettings(objKey){
            return angular.copy(_.get(containerGlobalConfigSelections, objKey, {}));
        }

        /**
         * Builds a Workflow object with initial state
         * @memberOf WorkflowsClassService
         * @param {object} data
         * @returns {Workflow}
         */
        function newWorkflow(data){
            var newWF = new Workflow(
                null,
                {
                    '1': new State(
                        new Document('Start', null, false, [], null),
                        new Display(1)
                    )
                }
            );
            if(data){
                angular.extend(newWF, data);
            }
            return newWF;
        }

        /**
         * newIndex method - generates index for new state
         * @memberOf Workflow
         * @returns {number}
         */
        Workflow.prototype.newIndex = function(){
            var index = 2;
            while(this.objects[index] != null){
                index++;
            }
            return index;
        };

        /**
         * newDocument method - creates Document State
         * @memberOf Workflow
         * @param {Array} forms
         * @param {boolean} isFinal
         * @returns {State}
         */
        Workflow.prototype.newDocument = function(forms, isFinal){
            var index = this.newIndex();
            return new State(
                new Document('Workflow State ' + index, null, forms, null, isFinal),
                new Display(index)
            );
        };

        /**
         * newDocument method - creates Decision State
         * @memberOf Workflow
         * @returns {State}
         */
        Workflow.prototype.newDecision = function(){
            return new State(
                new Decision('Workflow State ' + this.newIndex(), null),
                new Display(-1)
            );
        };

        /**
         * newDocument method - creates Decision State
         * @memberOf Workflow
         * @param {string} subType
         * @param {object} params
         * @returns {State}
         */
        Workflow.prototype.newProcess = function(subType, params){
            return new State(
                new Process('Workflow State ' + this.newIndex(), null, subType, params),
                new Display(-1)
            );
        };

        /**
         * getForms method - retrieves ids for forms available to workflow state
         * @memberOf Workflow
         * @param {string} id
         * @returns {Array}
         */
        Workflow.prototype.getForms = function(id){
            var forms = [];
            for (var wfoId in this.objects) {
                if (this.objects.hasOwnProperty(wfoId) && wfoId !== id) {
                    var wfObject = this.objects[wfoId];
                    var wfoForms = [];
                    if (wfObject.model.type === 'decision') {
                        if (wfObject.model.to[id]) {
                            wfoForms = this.getForms(wfoId);
                        }
                    }
                    else if (wfObject.model.to.indexOf(id) >= 0) {
                        if(wfoId !== '1') {
                            wfoForms = this.getForms(wfoId);
                        }
                        if(wfObject.model.type === 'document'){
                            wfoForms = wfoForms.concat(wfObject.model.forms);
                        }
                    }
                    for (var i = 0; i < wfoForms.length; i++) {
                        var formId = wfoForms[i];
                        if (forms.indexOf(formId) < 0) {
                            forms.push(formId);
                        }
                    }
                }
            }
            return forms;
        };

        Workflow.prototype.getStates = function(id){
            var wf = this;
            var states = [];

            _.forEach(wf.objects, function(wfObject, wfoId){
                if(wfoId !== id && wfObject.model.type === 'document' && wfObject.model.to.indexOf(id) >= 0){
                    var state = angular.copy(wfObject.model);
                    state.id = wfoId;

                    states.unshift(state);

                    if(wfoId !== '1') {
                        states = wf.getStates(wfoId).concat(states);
                    }
                }
            });

            return states;
        };

        return {
            newWorkflow: newWorkflow,
            getParams: getParams,
            getDefaultSettings: getDefaultSettings
        };
    })
;